function onIdentifyCompleteCallback() {
  klaviyo.push([
    "track",
    "Custom Event",
    {
      Country: "United States",
    },
  ]);
}
// const identityProperties = {
//   $email: "hh@qq.com",
// };
export function identify(identityProperties:any){
    klaviyo.identify(identityProperties, onIdentifyCompleteCallback);
}
// 查看商品
export function productView(data) {
  try {
    var item = {
      ProductName: data.ProductName,
      ProductID: data.ProductID,
      Category: commodityCategroy(data.Category),
      ImageURL: data.ImageURL,
      Price: data.Price,
      Quantity: data.Quantity,
    };
    klaviyo.push(["track", "Product View", item]);
    
    window.dataLayer.push('Product View',{
      goodsId:  data.ProductID,
      goodsName: data.ProductName,
      goodsMoney: data.Price,
      quantity: data.Quantity,
      category: commodityCategroy(data.Category),
      imageURL: data.ImageURL
    })
  } catch (error) {
   console.error(error) 
  }
}
// 加购商品
export function AddToCart(data) {
  var item = {
    ProductName: data.ProductName,
    ProductID: data.ProductID,
    Category: commodityCategroy(data.Category),
    ImageURL: data.ImageURL,
    Price: data.Price,
    Quantity: data.Quantity,
  };
  klaviyo.push(["track", "Add To Cart", item]);

  window.dataLayer.push('Add To Cart',{
    goodsId:  data.ProductID,
    goodsName: data.ProductName,
    goodsMoney: data.Price,
    quantity: data.Quantity,
    category: commodityCategroy(data.Category),
    imageURL: data.ImageURL
  })
  gtag('event', 'Add To Cart', {
    'send_to': 'AW-10865757270/6trkCMH6vrIDENaYmb0o',
    'value':  data.Price,
    'currency': 'USD',
    'ProductName': data.ProductName,
    'ProductID': data.ProductID,
    'Category': commodityCategroy(data.Category),
    'ImageURL': data.ImageURL,
    'Price': data.Price,
    'Quantity': data.Quantity,
  });

}
// 购物车移除商品
export function removeCartProduct(data) {
  var item = {
    ProductName: data.ProductName,
    ProductID: data.ProductID,
    Category: commodityCategroy(data.Category),
    ImageURL: data.ImageURL,
    Price: data.Price,
    Quantity: data.Quantity,
  };
  
  klaviyo.push(["track", "Remove Add To Cart", item]);

  window.dataLayer.push('Remove Product',{
    goodsId:  data.ProductID,
    goodsName: data.ProductName,
    goodsMoney: data.Price,
    quantity: data.Quantity,
    category: commodityCategroy(data.Category),
    imageURL: data.ImageURL
  })
}
// 产品搜索
export function productSearch(data) {
  var item = {
    SearchTerm: data.SearchTerm,
    ReturnedResults: data.total,
  };
  klaviyo.push(["track", " Product Search", item]);
  window.dataLayer.push('Search',{
    goodsName: data.SearchTerm,
    quantity: data.total,
  })
}
//  折扣使用
export function discountApplied(data) {
  var item = {
    activityId: data.activityId,
    activityName: data.activityName,
    discountMoney: data.discountMoney,
  };
  klaviyo.push(["track", "Discount Applied", item]);
}
// 创建订单
export function createOrder(data) {
    var item = {
        Products: data
    }
    console.log(item)
    klaviyo.push(["track", "Create Order", item]);
    window.dataLayer.push('Create Order',item)
  }
// 发起结账
export function InitiateCheckout(data){
  var item = {
    ProductName: data.ProductName,
    ProductID: data.goods_id,
    Category: data.goodsInformation.goodsCategory,
    ImageURL: data.fileUrl + item.goodsInformation.goodsImage[0].storageKey,
    Price: data.goodsInformation.goodsPrice,
    Quantity: data.Quantity,
  }
  klaviyo.push(["track", "InitiateCheckout", data]);
}
// 购买
export function Purchase(data){
  klaviyo.push(["track", "InitiateCheckout", data]);
  window.dataLayer.push('Purchase',data)

}
function commodityCategroy(item) {
  switch (item) {
    case "0":
      return "常规商品";
      break;
    case "1":
      return "简单定制";
      break;
    case "2":
      return "深度定制";
      break;
  }
}

